import { useEffect, useState } from 'react';
import { useGetBranches } from './logic/use-get-branches';
import Center from '../../components/center';
import Spinner from '../../components/spinner';
import TableReservationCreateBranchError from './component/table-reservation-create-branch-error';
import { useSearchParams } from 'react-router-dom';
import NailTableReservationCreateForm
  from './nail-component/nail-service-selection-1/nail-table-reservation-create-form.tsx';
import FooterBar from '@app/components/footer-bar.tsx';
import NailTableReservationCreateForm2
  from '@app/page/table-reservation/nail-component/nail-service-selection-2/nail-table-reservation-create-form-2.tsx';

const NailTableReservationCreate = (props: { nailType: string }) => {
  const [params] = useSearchParams();
  const companyID = params.get('companyID');
  const branchID = params.get('branchID');

  const { data: branchesData, loading } = useGetBranches(companyID);
  const [selectedBranch, setSelectedBranch] = useState<string>('');

  const selectedBranchData = branchesData?.storefront_tableReservationBranches.find(
    (branch) => branch.id === selectedBranch,
  );

  useEffect(() => {
    setSelectedBranch(branchID ?? '');
  }, [branchID]);

  useEffect(() => {
    if (selectedBranchData) {
      document.title = `Reservation at ${selectedBranchData.name}`;
    } else {
      document.title = 'Reservation';
    }
  }, [selectedBranchData]);

  if (companyID === null) {
    return <div>Company ID is required</div>;
  }

  if (!branchID) {
    return <div>BranchID is require</div>;
  }

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!selectedBranchData) {
    return (
      <div>
        <TableReservationCreateBranchError />
        <FooterBar />
      </div>
    );
  }

  return (
    <>
      {props.nailType == '1' &&
        <NailTableReservationCreateForm selectedBranch={selectedBranchData} branchID={branchID} companyID={companyID} />
      }

      {props.nailType == '2' &&
        <NailTableReservationCreateForm2 selectedBranch={selectedBranchData} branchID={branchID} companyID={companyID} />
      }
      <FooterBar />
    </>
  );
};

export default NailTableReservationCreate;
