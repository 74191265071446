import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const MUTATION = graphql(`
  mutation storefront_cancelAppointment($id: ID!) {
    storefront_cancelAppointment(id: $id) {
      id
    }
  }
`);

export default function useCancelAppointment() {
  return useMutation(MUTATION, {});
}
