import { createBrowserRouter } from 'react-router-dom';
import MenuPreviewPage from './page/menu-preview/menu-preview-page.tsx';
import TableReservationDetail from '@app/page/table-reservation-detail/table-reservation-detail.tsx';
import TableReservationCreate from '@app/page/table-reservation/table-reservation-create.tsx';
import { OnlineOrderRouter } from '@app/page/online-order/online-order.router.tsx';
import NailTableReservationCreate from '@app/page/table-reservation/nail-table-reservation-create.tsx';
import NailTableReservationDetail from './page/table-reservation-detail/nail-table-reservation-detail.tsx';
import { AppoitmentRouter } from '@app/page/appointment/appoitment.router.tsx';
import RootLayout from '@app/root-layout.tsx';

const globalRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/menu-preview',
        element: <MenuPreviewPage />,
      },
      {
        path: 'table-reservation',
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                element: <TableReservationCreate />,
              },
              {
                path: 'track',
                element: <TableReservationDetail />,
              },
            ],
          },
          {
            path: 'nail',
            children: [
              {
                path: '',
                element: <NailTableReservationCreate nailType="1" />,
              },
              {
                path: 'track',
                element: <NailTableReservationDetail />,
              },
            ],
          },
          {
            path: 'nail2',
            children: [
              {
                path: '',
                element: <NailTableReservationCreate nailType="2" />,
              },
              {
                path: 'track',
                element: <NailTableReservationDetail />,
              },
            ],
          },
        ],
      },
      OnlineOrderRouter,
      AppoitmentRouter,
    ],
  },
]);

export default globalRouter;
