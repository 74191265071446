import { RouteObject } from 'react-router-dom';
import ProductPickerPage from '@app/page/online-order/product-picker/product-picker-page.tsx';
import OnlineOrderDetail from '@app/page/online-order/detail/online-order-detail.tsx';

export const OnlineOrderRouter: RouteObject = {
  path: 'online-order',
  children: [
    {
      path: 'start',
      element: <ProductPickerPage />,
    },
    {
      path: ':onlineOrderID',
      element: <OnlineOrderDetail />,
    },
  ],
};
