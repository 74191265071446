import { ServiceProductItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { formatRawCurrency } from '@app/components/price/currency-formatter.ts';
import {
  AppointmentMonitor,
  useAppointmentInfo,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useTranslate } from '@tolgee/react';

export default function SelectedProduct(props: { selectedProduct: ServiceProductItemFragment | null | undefined }) {
  const { selectedProduct } = props;
  const { branch, setAppointmentMonitor } = useAppointmentInfo((state) => state);
  const { t } = useTranslate();

  if (!selectedProduct || !branch) {
    return null;
  }

  const configuration = getFragmentData(MenuProductConfigurationFragment, selectedProduct.configurations);
  const configurationValue = getFragmentData(MenuProductConfigurationValueFragment, configuration[0].values);
  const currencyData = formatRawCurrency(configurationValue[0].price, branch.company.settings.currency);

  const handleClick = () => {
    setAppointmentMonitor(AppointmentMonitor.start);
  };

  return (
    <div onClick={handleClick}>
      <p className="mb-1 mt-2 text-sm font-medium text-gray-700">
        {t('storefront-appointment.create.service-and-time.form-input.selected-service', 'Selected service')}
      </p>
      <div
        key={selectedProduct.id}
        className="flex w-full items-center justify-between rounded-xl bg-stone-100 px-4 py-3 hover:bg-stone-200"
      >
        <div>
          <p className="mb-0.5 text-sm">{`${selectedProduct.code}. ${selectedProduct.title}`}</p>
          <p className="text-xs text-gray-500">
            {selectedProduct.appointmentMenuProductData.duration}{' '}
            {t('storefront-appointment.create.minutes', 'Minutes')}
          </p>
        </div>

        <div>
          <p className="text-base text-gray-500">{currencyData.value + currencyData.symbol}</p>
        </div>
      </div>
    </div>
  );
}
