import { ServiceProductItemFragment } from '@app/graphql/types/graphql.ts';
import { useAppointmentInfo } from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useTranslate } from '@tolgee/react';

export default function SelectedMember(props: { selectedProduct: ServiceProductItemFragment | null | undefined }) {
  const { selectedProduct } = props;
  const { branch, memberId, selectMember } = useAppointmentInfo((state) => state);
  const { t } = useTranslate();

  if (!selectedProduct || !branch) {
    return <></>;
  }

  // No member to select
  const isEmpty = selectedProduct.appointmentMenuProductData.nailAppointmentMenuProductTeams.every((team) => {
    return team.team.teamMember.length == 0;
  });

  if (isEmpty) {
    return <></>;
  }

  return (
    <div>
      <p className="mb-1 mt-2 text-sm font-medium text-gray-700">
        {t('storefront-appointment.create.service-and-time.form-input.selected-member', 'Selected employee')}
      </p>
      <div key={selectedProduct.id} className="flex flex-wrap items-center justify-between gap-1 space-x-4">
        {selectedProduct.appointmentMenuProductData.nailAppointmentMenuProductTeams.map((team) => {
          return (
            <div key={team.id} className="flex items-center">
              <select
                value={memberId ?? undefined}
                className="block w-full rounded-md border-gray-300 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  if (e.target.value === 'unselected') {
                    selectMember(null);
                    return;
                  }

                  const selectedMemberId = e.target.value;
                  selectMember(selectedMemberId);
                }}
              >
                <option value={'unselected'}>
                  {t('storefront-appointment.create.service-and-time.form-input.anyone', 'Anyone')}
                </option>

                {team.team.teamMember.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.user.firstName} {member.user.lastName}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
}
