import { Outlet } from 'react-router-dom';
import FooterBar from '@app/components/footer-bar.tsx';

export default function RootLayout() {
  return (
    <div>
      <Outlet />
      <FooterBar />
    </div>
  );
}
