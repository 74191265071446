import AppointmentServiceStart from '@app/page/appointment/appointment-create/component/appointment-service-start.tsx';
import AppointmentServiceAndTime from '@app/page/appointment/appointment-create/component/appointment-service-and-time.tsx';
import AppointmentServiceSelection from '@app/page/appointment/appointment-create/component/appointment-service-selection.tsx';
import AppointmentServiceContactInfo from '@app/page/appointment/appointment-create/component/appointment-service-contact-info.tsx';
import { useAppointmentBranchConfiguration } from '@app/page/appointment/appointment-create/logic/use-get-appointment-branch-configuration.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFragmentData } from '@app/graphql/types';
import { ServiceFragment, ServiceProductFragment } from '@app/page/online-order/model/appointment-fragment.ts';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { useCreateAppointment } from '@app/page/appointment/appointment-create/logic/use-create-appointment.ts';
import {
  AppointmentMonitor,
  useAppointmentInfo,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useEffect } from 'react';
import { useTranslate } from '@tolgee/react';
import Loading from '@app/components/loading.tsx';

export default function AppointmentCreate() {
  const [searchParams] = useSearchParams();
  const branchId = searchParams.get('branchID') ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();

  const state = useAppointmentInfo((state) => state);
  const { currentMonitor, appointmentInput, pickedProduct, setBranch, reset } = state;

  const { data, loading } = useAppointmentBranchConfiguration(branchId);
  const [create] = useCreateAppointment();

  const menu = getFragmentData(ServiceFragment, data?.storefront_appointmentBranchConfiguration.menu);
  const branch = getFragmentData(BranchFragment, data?.storefront_appointmentBranchConfiguration.branch);

  useEffect(() => {
    if (!branch) {
      return;
    }
    setBranch(branch);
  }, [branch, setBranch]);

  if (loading) {
    return <Loading />;
  }

  if (!branch) {
    return <>Branch not found</>;
  }

  if (!menu) {
    return <>Menu not found</>;
  }

  const selectedProduct = getFragmentData(
    ServiceProductFragment,
    menu.menuProducts.find((productFragment) => {
      const product = getFragmentData(ServiceProductFragment, productFragment);
      return product.id == pickedProduct?.productID;
    })
  );

  const handleSubmit = () => {
    if (!pickedProduct) {
      alert(t('storefront-appointment.create.error.no-picked-product', 'Please select a product'));
      return;
    }

    create({
      variables: {
        branchID: branchId,
        input: appointmentInput,
        pickedProductInput: [
          {
            ...pickedProduct,
            memberID: state.memberId,
          },
        ],
      },
    })
      .then((result) => {
        reset();
        navigate(`/appointment/track/${result.data?.storefront_createAppointment.id ?? ''}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  switch (currentMonitor) {
    case AppointmentMonitor.start:
      return <AppointmentServiceStart menu={menu} branch={branch} />;
    case AppointmentMonitor.time:
      return <AppointmentServiceAndTime selectedProduct={selectedProduct} />;
    case AppointmentMonitor.selection:
      return <AppointmentServiceSelection />;
    case AppointmentMonitor.contactInfo:
      return <AppointmentServiceContactInfo selectedProduct={selectedProduct} handleSubmit={handleSubmit} />;
    default:
      return <>Something wrong</>;
  }
}
