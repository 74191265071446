import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const MUTATION = graphql(`
    mutation storefront_CreateAppointment($branchID: ID!,$input: Storefront_CreateAppointmentInput!, $pickedProductInput: [PickedProductInput!]!) {
        storefront_createAppointment(branchID: $branchID, input: $input, pickedProductInput: $pickedProductInput) {
            id
        }
    }
`);

export function useCreateAppointment() {
  return useMutation(MUTATION);
}
