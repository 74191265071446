import { useEffect, useState } from 'react';
import DestroyButton from '@app/components/destroy-button.tsx';
import { Dialog, DialogPanel } from '@headlessui/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import { useParams } from 'react-router-dom';
import useCancelAppointment from '@app/page/appointment/appointment-track/logic/use-cancel-appointment.ts';
import { useTranslate } from '@tolgee/react';

export default function CancelButtonDialog() {
  const appointmentId = useParams().appointmentId ?? '';

  const [isOpen, setIsOpen] = useState(false);
  const [cancel] = useCancelAppointment();
  const { t } = useTranslate();

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    close();
  }, []);

  const onCancel = () => {
    cancel({
      variables: {
        id: appointmentId,
      },
    })
      .then(() => {
        setIsOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <button
        className={'w-full rounded-md px-2.5 py-1.5 text-sm font-semibold text-red-600 hover:bg-gray-300 sm:w-auto'}
        onClick={open}
      >
        {t('storefront-appointment.track.cancel', 'Cancel appointment')}
      </button>

      <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4 text-center backdrop-brightness-75">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-xl p-6 text-left align-middle transition-all">
              <Card>
                <CardHeader title={t('storefront-appointment.track.cancel-dialog.header', 'Cancel appointment')} />
                <CardContent>
                  <p className="italic text-gray-600">
                    {t(
                      'storefront-appointment.track.cancel-dialog.description',
                      'Are you sure you want to cancel? This action cannot be undone'
                    )}
                  </p>
                </CardContent>
                <CardFooter>
                  <div className="flex w-full flex-col items-center justify-between gap-y-4 sm:flex-row">
                    <DestroyButton onClick={onCancel} className="w-full sm:w-auto">
                      {t('storefront-appointment.track.cancel-dialog.button.cancel', 'Cancel service')}
                    </DestroyButton>
                    <PrimaryButton className="w-full bg-indigo-600 px-6 hover:bg-indigo-400 sm:w-auto" onClick={close}>
                      {t('storefront-appointment.track.cancel-dialog.button.dismiss', 'Dismiss')}
                    </PrimaryButton>
                  </div>
                </CardFooter>
              </Card>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
